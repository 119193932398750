//
//
// user-matt.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.selected-parameter {
	border-width: 5px;
	border-color: black;
}

.unselected-parameter {
	border-width: 5px;
	border-color: transparent;
}

.selected-div {
	border: 1px solid red !important;
}

.zone-graphic-setup-card {
	background-color:  rgba(#0475d1, .1);
}

.card-search {
	min-width: 18rem;
  }
  
  @media (max-width: 575.98px) {
	.card-search {
	  min-width: 16rem;
	}
  }
  
.card-search .card-header, .card-notification .card-footer {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
  }
  
  .card-search .list-group-flush .list-group-item {
	padding: 0;
  }
  
  .card-search .list-group-flush .list-group-item .notification-flush {
	padding: 0.5rem 1rem;
  }
  
  .card-search .list-group-flush .list-group-title {
	background-color: #f9fafd;
	font-weight: 600;
	font-size: 0.69444rem;
	color: #748194;
	padding: 0.25rem 1.25rem;
  }
  

.progress, .progress-bar { height: 5px;}

.form-check-input {
	margin-top: 0.25rem;
}

.drag-drop-box-chart-report {
	border: 1px dashed grey;
	border-radius: 5px;
	text-align: left;
}

#input-range-y,
#input-range-y2 {
	padding: 40px;
}

.upper {
	text-transform: uppercase;
}

.unread-indicator::before {
	background: linear-gradient(#a20404, #e04e4e);
}

.tab-no-gutters .RRT__panel {
	padding: 0;
}

.RRT__tab {
	font-size: 12px;
	text-align: center;
}

.MuiTable-Small .MuiTableCell-head {
	white-space: pre-line;
	text-align: center;
	width: 40px;
	line-height: 15px;
	vertical-align: bottom;
}

.react-grid-Grid {
	font-size: 12px;
}

.alarm-MainHi,
.alarm-MainLow {
	background-color: rgb(228, 28, 28);
	color: white;
}

.alarm-WarnHi,
.alarm-WarnLow {
	background-color: rgb(252, 221, 50);
}

.rdg_innerCell {
	padding: 8px;
}

.rdg-no-gutters .react-grid-Cell {
	padding: 0;
}

.selected-alarm {
	color: black;
	font-weight: bold;
	border: 2px solid black;
}

.rct-disabled {
	color: lightgray;
}